// import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";

const waves = {
 
  init() {
    window.addEventListener('load', () => {

        // const footer = document.querySelector(".js-pageFooter")
        
        // gsap.registerPlugin(ScrollTrigger);
        // ScrollTrigger.create({
        //     trigger: footer,
        //     markers:false,
        //     start:"top 95%",
        //     end:"bottom 0%", 
        //     scrub: 1,

        //     onEnter: () => {
        //       gsap.to(footer, { duration: 0.5, backgroundColor: '#233f63'})
        //     },
            
        //     // onLeaveBack: () => {
        //     //   gsap.to(footer, { duration: 0.5, backgroundColor: '#f8f8f8', color: '#f8f8f8'})
        //     // },
          
            
        //   })


        // if(footer){
        //     gsap.to({
        //         trigger: footer,
        //         start: "top bottom",
        //         end: "bottom top",
        //         scrollTrigger: {
        //             scrub: 1,
        //             onEnter: () => {
        //                 gsap.to(footer, { duration: 1.0, backgroundColor: '#ff0000'})
        //             },
        //             onLeaveBack: () => {
        //                 gsap.to(footer, { duration: 1.0, backgroundColor: '#ffffff'})
        //             },
        //         }
        //     })
        // }
    })
  }
};

export default waves;
