/*
 * objectList
 */

const objectList = {
    cfg:  {
        name: 'objectList',
        selectors: {
            list: '.js-objectList .this-list',
            images: '.js-objectList .this-images', 
        },
    },

    removeAllClasses(imagesItems){
        imagesItems.forEach((imageItem) => {
            imageItem.classList.remove('is-active')
        });	
        return false;
    },

    init() {
        const list = document.querySelector(this.cfg.selectors.list);
        const images = document.querySelector(this.cfg.selectors.images);

        if(list && images){

            const listItems = list.querySelectorAll(".this-row");
            const imagesItems = images.querySelectorAll(".this-slide");
            const imagesItemsLength = imagesItems.length;

            listItems.forEach((listItem, index) => {
                listItem.onmouseover = () => {
                    this.removeAllClasses(imagesItems);
                    imagesItems[index].classList.add('is-active');
                };
            });

            list.onmouseleave = () => {
                this.removeAllClasses(imagesItems);
                imagesItems[imagesItemsLength - 1].classList.add('is-active');
            }
        }
    }
};

export default objectList;
