/*
 * m-overlay
 */

const overlay = {
  cfg: {
    name: `overlay`
  },
  init() {
    // window.addEventListener('load', () => {
    //   const nav = document.getElementById('overlay')

    //   if (nav) {
    //     let lastItem
    //     // let itemBg
    //     const dropdownItems = nav.querySelectorAll('.has-dropdown')
    //     // let navBg = document.querySelector('.js-navBg')

    //     dropdownItems.forEach((item) => {
    //       item.onclick = () => {
    //         // itemBg = item.querySelector('.js-copyBg')

    //         // navBg.innerHTML = itemBg.outerHTML
    //         item.classList.toggle('is-open')

    //         if (lastItem && lastItem != item) lastItem.classList.remove('is-open')

    //         if (item.classList.contains('is-open')) {
    //           document.body.classList.add('nav-is-open')
    //         } else {
    //           document.body.classList.remove('nav-is-open')
    //         }

    //         lastItem = item
    //       }
    //     })
    //   }
    // })
  }
}

export default overlay
