/**
 * Image Slider
 */

import Flickity from 'flickity-fade'

const pageSlider = {
  cfg: {
    slider: '.js-pageSlider',
    sliderOptions: {
      cellAlign: 'left',
      contain: false,
      draggable: false,
      friction: 1,
      lazyLoad: 2,
      fade: true,
      autoPlay: 5000,
      selectedAttraction: 0.1,
      // groupCells: 1,
      // setGallerySize: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: false,
      arrowShape: { 
        x0: 10,
        x1: 60, y1: 50,
        x2: 65, y2: 45,
        x3: 20
      }
      // watchCSS: true
    }
  },

  init() {
    window.addEventListener('load', () => {

      const sliders = [...document.querySelectorAll(this.cfg.slider)]
      if (sliders) {
        sliders.forEach((slider) => {

          const prev = slider.parentNode.querySelector('.prev');
          const next = slider.parentNode.querySelector('.next');

          const flkty = new Flickity(slider, this.cfg.sliderOptions)
          flkty.resize()

          setTimeout(() => {
            flkty.resize()
            slider.classList.add('is-ready')
          }, 1000)

          // previous
          if(prev){
            prev.addEventListener('click', function () {
              flkty.previous(true, false);
            });
          }

          // next
          if(next){
            next.addEventListener('click', function () {
              flkty.next(true, false);
            });
          }
        })
      }
    })
  }
}

export default pageSlider
